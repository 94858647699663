import { Snowflake } from "lucide-react";
import {
    GoogleLogo,
    AppleLogo,
    DeloitteLogo,
    AdobeLogo,
    EYLogo,
    MicrosoftLogo,
    TiktokLogo,
    JPMorganLogo,
    WellsFargoLogo,
    TinderLogo,
    LinkedInLogo,
    kpmglogo,
    metalogo,
    capitalonelogo,
    BandCLogo,
    doordashlogo,
    bloomberglogo,
    fazelogo,
    hpLogo,
    intellogo,
    kialogo,
    nasalogo,
    nbclogo,
    oraclelogo,
    paramountLogo,
    protivitilogo,
    PWCLogo,
    redbulllogo,
    statefarmlogo,
    vmwarelogo,
    walmartlogo,
    warnbroslogo,
    BakerTillyLogo,
    RippleMatchLogo,
    BlackstoneLogo,
    MonsterLogo,
    RyanLLCLogo,
    SAPLogo,
    CrowdstrikeLogo,
    PepsiLogo,
    CiscoLogo,
    TakenakaPartnersLogo,
    AutodeskLogo,
    PfizerLogo,
    SiemensLogo,
    AmazonLogo,
    RedbullLogo,
    UBSLogo,
    RentazonLogo,
    ThalesLogo,
    ElevatedLogo,
    BiologicalSciencesLogo,
    OITLogo,
    EdisonLogo,
    YerbaMateLogo,
    CaliforniaISOLogo,
    KumonLogo,
    MerageLogo,
    OriginsWorldFoodsLogo,
    KimleyHornLogo,
    ConcordiaLogo,
    ExperianLogo,
    WiseAssistantLogo,
    DepopLogo,
    AcornsLogo,
    HORLogo,
    GitHubLogo,
    YouTubeLogo,
    LoroPianaLogo,
    USAALogo,
    BlackrockLogo,
    TripleALogo,
    SnowflakeLogo,
    RadixWellnessLogo,
    RumeLogo,
    VerizonLogo,
    MeetTheFlockersLogo,
    SwayLogo,
    CapitalGroupLogo,
    BrenLogo,
    RaytheonLogo,
    GlobalFoundriesLogo,
  } from "../Assets";
  import DefaultHeadshot from "./ActiveHeadshots/Default Headshot.webp";
  

export const companyHash = {
    "Oriana Kou": RedbullLogo,
    "Jessica Choi": AmazonLogo,
    "Aarush Inamdar": AdobeLogo,
    "Alex Kao": UBSLogo,
    "Sarah Kim": KumonLogo,
    "Yan Amy Zhou": JPMorganLogo,
    "Alexandra Ancheta": BakerTillyLogo,
    "Tiffany Cheng": AppleLogo,
    "Tiffany Vo": MerageLogo,
    "Izella Han": RippleMatchLogo,
    "Anna Shan": BlackrockLogo,
    "Isabella Tan": MonsterLogo, //GRAD
    "Amy Tu": SnowflakeLogo,
    "Mason Whang": LoroPianaLogo,
    "Edmund Ma": RyanLLCLogo,
    "Chris Wang": YerbaMateLogo,
    "Andrew Sou": BrenLogo,
    "Annie Nguyen": SAPLogo,
    "Alexis Lin": AmazonLogo,
    "Catelynn Chen": EdisonLogo,
    "Elle Hsu": PepsiLogo,
    "Anuj Patel": nasalogo,
    "Rithika Pothi": capitalonelogo,  //GRAD
    "Andrew Hauber": CapitalGroupLogo,
    "Allen Lai": CiscoLogo,
    "Cindy Chen": BiologicalSciencesLogo,
    "Daniel Kim": TakenakaPartnersLogo,
    "Donny Chau": GlobalFoundriesLogo,
    "Haley Yoon": SwayLogo,
    "Katie Wang": GitHubLogo,
    "Lauren Cho": RaytheonLogo,
    "Max Vano": SiemensLogo,
    "Pranay Macherla": AmazonLogo,
    "Mina Nguyen": RumeLogo,
    "Vinh Tran": CaliforniaISOLogo,
    "Daniel Vercueil": MerageLogo,
    "Tommy Nguyen": MeetTheFlockersLogo,
    "Alex Sriprathum": KimleyHornLogo,
    "Nico Simonian": USAALogo,
    "Carl Qiao": PWCLogo,
    "Logan Kim": ConcordiaLogo,
    "Ryan Park": ExperianLogo,
    "Melinda Do": WiseAssistantLogo,
    "Travis Yen": DepopLogo,
    "Tyler Ho": DeloitteLogo,
    "Erin Tran": AcornsLogo,
    "Bradly Ho": HORLogo,
    "Brandon Koh": YouTubeLogo,
    "Default Headshot": DefaultHeadshot,
  };
    